<template>
  <div class="home">
    <div class="home-top">
      <SectionBanner />
    </div>
    <div class="home__content">
      <GameCenter />
      <Casino />
      <TopNoHu />
      <Promotion />
      <Introduce />
      <Contact />
      <SeoContent />
      <SeoImage />
    </div>
  </div>
</template>
<script setup lang="ts">
const SectionBanner = defineAsyncComponent(() => import('~/components/mobile/pages/home/banner/index.vue'))
const GameCenter = defineAsyncComponent(() => import('@/components/mobile/pages/home/game-center/index.vue'))
const Casino = defineAsyncComponent(() => import('@/components/mobile/pages/home/casino/index.vue'))
const TopNoHu = defineAsyncComponent(() => import('@/components/mobile/pages/home/top-nohu/index.vue'))
const Promotion = defineAsyncComponent(() => import('@/components/mobile/pages/home/promotion/index.vue'))
const Contact = defineAsyncComponent(() => import('@/components/mobile/pages/home/section-contact/index.vue'))
const Introduce = defineAsyncComponent(() => import('@/components/mobile/pages/home/section-introduce/index.vue'))
const SeoContent = defineAsyncComponent(() => import('@/components/common/seo-content.vue'))
const SeoImage = defineAsyncComponent(() => import('@/components/common/branch-info.vue'))
</script>
<style scoped lang="scss" src="~/assets/scss/components/mobile/pages/home/index.scss"></style>
